import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/base-component';
//import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/tab';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Navigatie dropdown
	$('.navbar .dropdown').on('mouseenter', function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').on('click', function(){
		location.href = this.href;
	});

	// Zoekfunctie
	$('header .search-icon').on('click', function(){
		$('header #wp-block-search__input-1').toggleClass('search-active');
	});


	// Lazyload
	jQuery(".lazyload").lazyload();


	// Slideshow
	function headerSlideshow(){
		jQuery('.header-slideshow').slick({
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			adaptiveHeight: true,
			dots: true,
			prevArrow: '<button type="button" class="slick-prev"><</button>',
			nextArrow: '<button type="button" class="slick-next">></button>',
		});
	}

	headerSlideshow();

});